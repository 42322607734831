import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import '../App.css';

async function embedDashboard(embeddingContext, containerId, url) {
  const container = document.getElementById(containerId);

  const frameOptions = {
    url,
    container,
    scrolling: 'no',
    iframeResizeOnSheetChange: true, // use this option in combination with height: AutoFit,
    // to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
    width: '100%',
    height: '800px',
//     loadingHeight: '800px',
//     footerPaddingEnabled: true,
//     sheetTabsDisabled: false, // use this option to enable or disable sheet tab controls
//     undoRedoDisabled: true, // use this option to disable undo and redo buttons
//     resetDisabled: false, // use this option to disable reset button
  };

const contentOptions = {
      toolbarOptions: {
          export: false,
          undoRedo: false,
          reset: false
      },
      attributionOptions: {
          overlayContent: false,
      },
      onMessage: async (messageEvent, experienceMetadata) => {
          switch (messageEvent.eventName) {
              case 'CONTENT_LOADED': {
                  console.log("All visuals are loaded. The title of the document:", messageEvent.message.title);
                  break;
              }
              case 'ERROR_OCCURRED': {
                  console.log("Error occurred while rendering the experience. Error code:", messageEvent.message.errorCode);
                  break;
              }
              case 'PARAMETERS_CHANGED': {
                  console.log("Parameters changed. Changed parameters:", messageEvent.message.changedParameters);
                  break;
              }
              case 'SELECTED_SHEET_CHANGED': {
                  console.log("Selected sheet changed. Selected sheet:", messageEvent.message.selectedSheet);
                  break;
              }
              case 'SIZE_CHANGED': {
                  console.log("Size changed. New dimensions:", messageEvent.message);
                  break;
              }
              case 'MODAL_OPENED': {
                  window.scrollTo({
                      top: 0 // iframe top position
                  });
                  break;
              }
          }
      },
  };

  const dashboard = await embeddingContext.embedDashboard(frameOptions);

//   dashboard.on('load', console.log('Loading dashboard...'));
//   dashboard.on('error', console.log('Error loading QS dashboard'));
  return dashboard;
}

function onExpand() {
  // console.log('Q search bar opens');
}
function onCollapse() {
  // console.log('Q search bar closes');
}
async function embedQ(embeddingContext, containerId, url) {
  const container = document.getElementById(containerId);

  const frameOptions = {
    url,
    container,
    width: '100%',
    locale: 'en-US',
    className: 'fixed-height',
//     qSearchBarOptions: {
//       expandCallback: onExpand,
//       collapseCallback: onCollapse,
//       iconDisabled: true,
//       topicNameDisabled: false,
//       allowTopicSelection: false,
//     },
  };

  const contentOptions = {
      hideTopicName: false,
      allowTopicSelection: false,
      onMessage: async (messageEvent, experienceMetadata) => {
          switch (messageEvent.eventName) {
              case 'Q_SEARCH_OPENED': {
                  console.log("Do something when Q Search content expanded");
                  break;
              }
              case 'Q_SEARCH_CLOSED': {
                  console.log("Do something when Q Search content collapsed");
                  break;
              }
              case 'Q_SEARCH_SIZE_CHANGED': {
                  console.log("Do something when Q Search size changed");
                  break;
              }
              case 'CONTENT_LOADED': {
                  console.log("Do something when the Q Search is loaded.");
                  break;
              }
              case 'ERROR_OCCURRED': {
                  console.log("Do something when the Q Search fails loading.");
                  break;
              }
          }
      }
  };


  const qBar = await embeddingContext.embedQSearchBar(frameOptions, contentOptions);

//   qBar.on('load', console.log('Loading Q bar...'));
//   qBar.on('error', console.log('Error loading Q bar'));
  return qBar;
}

export { embedDashboard, embedQ };
